import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderPluginDto } from '@/features/orders/types';

import { Order, parseOrderBeforeSave } from '@/features/orders';

export class ParseOrderBeforeSave<T extends OrderPluginDto>
  implements PipelinePlugin<T>
{
  public execute(dataTransferObject: T): T {
    dataTransferObject.order = parseOrderBeforeSave(
      Order.from(dataTransferObject.order),
    );
    return dataTransferObject;
  }
}
