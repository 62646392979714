import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderPluginDto } from '@/features/orders/types';

import { loggerServicePlugin } from '@/features/core/logger';

export class LogInfoMessage<T extends OrderPluginDto>
  implements PipelinePlugin<T>
{
  constructor(private message: string) {}
  public execute(dataTransferObject: T): T {
    loggerServicePlugin.get().info(this.message, {
      orderId: dataTransferObject.order.id,
    });
    return dataTransferObject;
  }
}
