import type { Order, OrderPluginDto } from '@/features/orders';
import {
  AppCrashPlugin,
  LogOrderEventsPlugin,
  SaveOrderAndSyncScheduledPlugin,
} from '@/features/orders';
import { SetOrderStatusPlugin } from '@/features/orders';
import {
  EmitEventBusStatusChangePlugin,
  OrderEventNames,
  ordersServicePlugin,
  TrackOrderEventPlugin,
} from '@/features/orders';
import { OrderLocalStatus } from '@/features/orders';
import { BooleanSwitcherPlugin } from '@/features/orders';
import { PluginPipeline } from '@ads/plugin-pipeline';
import type { Ref } from 'vue';

import { loggerServicePlugin } from '@/features/core/logger';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';
import {
  CloneOrderObject,
  ParseOrderBeforeSave,
} from '@/features/handover/plugins';
import {
  PerformanceThresholdEnum,
  usePerformanceTracker,
} from '@/features/performance-tracker';

export async function completeHandoverOrderProcess(
  order: Order,
  processingSwitcher: Ref<boolean>,
): Promise<void> {
  const { startTracking } = usePerformanceTracker();

  try {
    const completeHandoverPlugin = new PluginPipeline<OrderPluginDto>();

    completeHandoverPlugin.registerPlugins([
      new CloneOrderObject(),
      new BooleanSwitcherPlugin(processingSwitcher, true),
      new EmitEventBusStatusChangePlugin('handover-completed'),
      new TrackOrderEventPlugin(
        ordersServicePlugin.get(),
        loggerServicePlugin.get(),
        OrderEventNames.pickup_completed,
        {
          skipSaving: true,
        },
      ),
      new SetOrderStatusPlugin(OrderLocalStatus.HandoverCompleted),
      new LogOrderEventsPlugin(`Handover for Order ${order.id} completed`),
      new ParseOrderBeforeSave(),
      new AppCrashPlugin(),
      new SaveOrderAndSyncScheduledPlugin(),
    ]);
    await completeHandoverPlugin.execute({
      order,
    });
  } catch (error) {
    loggerServicePlugin
      .get()
      .error('Error during the completed handover operation.');
    errorPlugin.get().handle(error);
    return;
  }
  startTracking(
    `handover-order-to-order-list`,
    PerformanceThresholdEnum.ROUTE_CHANGE,
  );
  void router.get().push('/');
  processingSwitcher.value = false;
}
