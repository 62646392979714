import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderPluginDto } from '@/features/orders/types';

import { unref } from 'vue';

export class CloneOrderObject<T extends OrderPluginDto>
  implements PipelinePlugin<T>
{
  public execute(dataTransferObject: T): T {
    dataTransferObject.order = Object.assign(
      {},
      unref(dataTransferObject.order),
    );
    return dataTransferObject;
  }
}
