import type { PipelinePlugin } from '@ads/plugin-pipeline';
import type { OrderPluginDto } from '@/features/orders/types';

import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import { $t } from '@/i18n';

export class ShowNotificationInfo<T extends OrderPluginDto>
  implements PipelinePlugin<T>
{
  constructor(private message: string) {}
  public execute(dataTransferObject: T): T {
    notificationPlugin.get().show({
      text: $t(this.message, {
        orderId: dataTransferObject.order.orderReference,
      }),
      type: NotificationType.Info,
    });
    return dataTransferObject;
  }
}
