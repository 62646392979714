import type { Order, OrderPluginDto } from '@/features/orders';
import { AppCrashPlugin } from '@/features/orders';
import { SaveOrderAndSyncScheduledPlugin } from '@/features/orders';
import { UpdateLocalStatusPlugin } from '@/features/orders';
import { OrderLocalStatus } from '@/features/orders';
import { BooleanSwitcherPlugin } from '@/features/orders';
import { PluginPipeline } from '@ads/plugin-pipeline';
import type { Ref } from 'vue';
import {
  LogInfoMessage,
  ShowNotificationInfo,
} from '@/features/handover/plugins';
import { loggerServicePlugin } from '@/features/core/logger';
import { $t } from '@/i18n';
import { errorPlugin } from '@/features/core/errors';
import router from '@/features/core/router';

export async function cancelOrder(
  order: Order,
  cancellationInProcess?: Ref<boolean>,
): Promise<void> {
  try {
    const cancelOrderPlugin = new PluginPipeline<OrderPluginDto>();

    const isOrderCancellable = [
      OrderLocalStatus.PickingInProgress,
      OrderLocalStatus.Paused,
      OrderLocalStatus.HandoverInProgress,
    ].includes(order.localStatus);

    if (!isOrderCancellable) {
      throw new Error($t('common.cancel-order-error.message'));
    }

    if (cancellationInProcess) {
      cancelOrderPlugin.registerPlugins([
        new BooleanSwitcherPlugin(cancellationInProcess, true),
      ]);
    }

    cancelOrderPlugin.registerPlugins(
      [
        new UpdateLocalStatusPlugin(
          loggerServicePlugin.get(),
          OrderLocalStatus.HandoverCancelled,
        ),
      ],
      (dto: OrderPluginDto) => {
        return dto.order.localStatus === OrderLocalStatus.HandoverInProgress;
      },
    );

    cancelOrderPlugin.registerPlugins(
      [
        new UpdateLocalStatusPlugin(
          loggerServicePlugin.get(),
          OrderLocalStatus.PickingCancelled,
        ),
      ],
      (dto: OrderPluginDto) => {
        return dto.order.localStatus !== OrderLocalStatus.HandoverInProgress;
      },
    );

    cancelOrderPlugin.registerPlugins([
      new SaveOrderAndSyncScheduledPlugin(),
      new LogInfoMessage(`Order ${order.id} cancelled (no items found)`),
      new ShowNotificationInfo(
        $t('common.cancel-order-toast.message', {
          orderId: order.orderReference,
        }),
      ),
      new AppCrashPlugin(),
    ]);

    await cancelOrderPlugin.execute({
      order,
    });
  } catch (error) {
    errorPlugin.get().handle(error);
  } finally {
    if (cancellationInProcess) cancellationInProcess.value = false;
    await router.get().push({
      name: 'default',
    });
  }
}
